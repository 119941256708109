<template>
  <div class="col-lg-4 col-md-6" style="margin: auto" v-if="d_data">
    <div class="education-block-grid style-1001">
      <div
        class="education-block-thumb n-shadow text-center"
        @click="showTutorProfile()"
      >
        <a href="javascript:void(0)">
          <cmp-tutoravatar
            :p_name="d_data.account.displayName"
            :p_avatar="d_data.account.avatar"
          />
        </a>
      </div>

      <div class="education-block-body" @click="showTutorProfile()">
        <h4 class="bl-title text-center">
          <h5>{{ d_data.account.displayName }}</h5>
        </h4>
      </div>
      <cmp-tutorating
        :p_ratingCount="d_data.account.ratingCount"
        :p_ratingValue="d_data.account.ratingValue"
        :p_readonly="true"
      />
      <div class="education-block-body">
        <h4 class="bl-title text-center">
          <span
            v-if="p_subjectInfo && p_subjectInfo.grade"
            class="tb-date"
            style="color: black"
            >Grade: <strong>{{ p_subjectInfo.grade }}</strong></span
          >
          <span
            v-if="p_subjectInfo && p_subjectInfo.curriculums"
            class="tb-date"
            style="color: black"
            >Curriculums:
            <strong style="text-transform: uppercase">{{
              p_subjectInfo.curriculums
            }}</strong></span
          >
        </h4>
      </div>

      <div class="cources-info">
        <!-- <div class="cources_info_first"> -->
        <div class="col-6">
          <ul>
            <li>
              <input
                type="number"
                class="form-control qty"
                v-model="d_quantity"
                step="1"
                min="1"
                value="1"
                title="Qty"
                size="4"
                placeholder=""
                inputmode="numeric"
                :disabled="p_notSpecifiedSessionsExists"
              />
            </li>
            <li class="theme-cl"></li>
          </ul>
        </div>
        <div class="col-2"><b-icon-x variant="success"></b-icon-x></div>
        <!-- <div class="cources_info_last"> -->
        <div class="col-4">
          <h4>{{ co_sessionPrice }}AED</h4>
        </div>
      </div>

      <div class="education-block-footer">
        <div class="education-block-author">
          <b-button
            v-if="p_notSpecifiedSessionsExists"
            type="button"
            @click="m_askAllocatesession"
            class="btn btn-loader"
            >Allocate Package Session<b-icon-compass>
              style="margin-left: 5px" ></b-icon-compass
            ></b-button
          >
          <b-button
            v-else
            type="button"
            @click="addProductToCart"
            class="btn btn-loader"
            >Add Session<b-icon-cart4 style="margin-left: 5px"></b-icon-cart4
          ></b-button>
        </div>
      </div>
    </div>
    <b-modal
      :id="co_tutorIdShortened"
      :cancel-disabled="true"
      :ok-disabled="true"
      scrollable
      :hide-footer="true"
      lazy
      size="xl"
      title="Profile"
    >
      <cmp-tutorprofile :tutor="d_data" />
    </b-modal>
  </div>
</template>

<script>
import c_tutorAvatar from "./cmp-tutoravatar";
import c_tutorRating from "./cmp-tutorrating";
import c_tutorProfile from "./cmp-tutorprofile";
import { fb_tutorsCollection } from "@/lib-core/lib-firebase";
import { mapGetters } from "vuex";
export default {
  name: "cmp-tutorcard",
  components: {
    "cmp-tutoravatar": c_tutorAvatar,
    "cmp-tutorating": c_tutorRating,
    "cmp-tutorprofile": c_tutorProfile,
  },
  data() {
    return { d_quantity: 1, d_data: null };
  },
  props: {
    p_email: {
      type: String,
    },
    p_subjectInfo: {
      type: Object,
      default: () => {},
    },
    p_notSpecifiedSessionsExists: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("md_fire", ["gt_sessionPrice"]),
    co_sessionPrice: function() {
      return this.gt_sessionPrice;
    },
    co_tutorLabel: function () {
      if (!this.d_data.account.displayName) return "This tutor";
      return this.d_data.account.displayName.split(" ")[0];
    },
    co_tutorIdShortened: function () {
      return this.p_email.split("@")[0];
    },
  },
  async mounted() {
    await this.m_fetchTutorProfile();
  },
  methods: {
    showTutorProfile() {
      this.$bvModal.show(this.co_tutorIdShortened);
    },
    async m_fetchTutorProfile() {
      try {
        if (this.d_data != null) return;
        const l_tutorDoc = await fb_tutorsCollection.doc(this.p_email).get();

        if (!l_tutorDoc.exists) {
          this.d_data = null;
          return;
        }
        this.d_data = l_tutorDoc.data();
      } catch (e) {
        this.d_data = null;
        // console.log(e);
      }
    },
    m_askAllocatesession() {
      this.$confirm({
        message: `Are you sure you want to use your package session?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: this.m_allocationSession,
      });
    },
    m_allocationSession(p_confirmed) {
      if (!p_confirmed) return;
      this.$emit("e_allocationSession", {
        tutor: this.m_transformTutorData(),
        quantity: 1,
      });
    },
    addProductToCart() {
      this.$emit("e_addProductToCart", {
        tutor: this.m_transformTutorData(),
        quantity: this.d_quantity,
      });
    },
    m_transformTutorData() {
      let l_ret = {};
      l_ret.displayName = this.d_data.account.displayName;
      l_ret.email = this.d_data.account.email;
      l_ret.avatar = this.d_data.account.avatar;

      if (this.p_subjectInfo.grade) l_ret.grade = this.p_subjectInfo.grade;
      if (this.p_subjectInfo.curriculums)
        l_ret.curriculums =
          this.$store.state.md_fire.st_curriculum ||
          this.p_subjectInfo.curriculums;
      return l_ret;
    },
  },
};
</script>

<style>
</style>