<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div class="rounded-top-0">
              <!--begin::Body-->
              <div class="card-body p-0">
                <div class="row justify-content-center">
                  <div class="col-xl-12 col-xxl-10">
                    <!--begin::Wizard Form-->
                    <form class="form" id="kt_form">
                      <div class="row justify-content-center">
                        <div class="col-xl-9">
                          <!--begin::Wizard Step 1-->
                          <div
                            class="step"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <div class="row">
                              <!-- begin::Group-->
                              <div
                                class="
                                  offset-3 offset-sm-0
                                  col-6 col-sm-12 col-md-4 col-lg-3 col-xl-3
                                "
                              >
                                <b-img
                                  fluid
                                  :src="co_getTutor.account.avatar"
                                  rounded
                                ></b-img>
                              </div>
                              <!--end::Group -->
                              <div
                                class="
                                  col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9
                                "
                              >
                                <!--begin::Group-->
                                <div class="form-group row">
                                  <label
                                    class="
                                      col-xl-2 col-lg-2 col-md-3 col-form-label
                                    "
                                    >Name</label
                                  >
                                  <div class="col-lg-10 col-xl-10 col-md-9">
                                    <b-form-input
                                      disabled
                                      v-model="co_getTutor.account.displayName"
                                      class="
                                        form-control
                                        form-control-solid
                                        form-control-lg
                                      "
                                    ></b-form-input>
                                  </div>
                                </div>
                                <!--end::Group-->
                                <!--begin::Group-->
                                <div class="form-group row">
                                  <label
                                    class="col-xl-2 col-lg-2 col-md-3 col-form-label"
                                    >Bio</label
                                  >
                                  <div class="col-lg-10 col-xl-10 col-md-9">
                                    <b-form-textarea
                                      disabled
                                      v-model="co_getTutor.account.bio"
                                      max-rows="50"
                                    ></b-form-textarea>
                                  </div>
                                </div>
                                <!--end::Group-->
                              </div>
                            </div>
                          </div>
                          <!--end::Wizard Step 1-->
                        </div>
                      </div>
                    </form>
                    <!--end::Wizard Form-->
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Wizard-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { calculateAge } from "../../lib-core/lib-helpers/lib-utils.js";
import moment from "moment";
export default {
  props: {
    tutor: Object,
  },
  computed: {
    co_getTutor() {
      return this.tutor;
    },
    co_age() {
      return calculateAge(moment, this.co_getTutor.account.dob.toDate());
    },
  },
};
</script>

<style>
.modal-body {
  padding: 1rem;
}
</style>