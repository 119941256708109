<template>
  <section style="min-height: 95vh" v-if="st_subject">
    <div class="container">
      <b-alert
        variant="success"
        style="position: fixed; right: 30px; bottom: 10px; z-index: 999"
        :show="d_isAddToCart"
      >
        Session(s) added to cart
        <b-icon-check></b-icon-check>
      </b-alert>
      <section class="page-title">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="breadcrumbs-wrap">
                <h1 class="breadcrumb-title">{{ st_subject.en }} Tutors</h1>
              </div>
            </div>
          </div>
        </div>
        <b-container>
          <b-row class="justify-content-md-center">
            <b-col sm="auto" md="auto" lg="auto" xl="auto">
              <div
                class="text-center"
                style="margin-top: 40px"
                v-if="st_subject.education == 's' && gt_curriculumsSelectItem"
              >
                <multiselect
                  selectLabel="Select"
                  deselectLabel="Remove"
                  class="multiselect-main-class"
                  style="
                    width: 50%;
                    margin: auto;
                    min-width: 300px;
                    max-width: 380px;
                  "
                  v-model="co_selectedCurriculum"
                  :options="gt_curriculumsSelectItem"
                  :close-on-select="true"
                  placeholder="All Curriculums"
                  label="en"
                  track-by="key"
                >
                </multiselect>
              </div>
            </b-col>
            <b-col sm="auto" md="auto" lg="auto" xl="auto">
              <div
                class="text-center"
                style="margin-top: 40px"
                v-if="st_subject.education == 's'"
              >
                <multiselect
                  selectLabel="Select"
                  deselectLabel="Remove"
                  class="multiselect-main-class"
                  style="
                    width: 50%;
                    margin: auto;
                    min-width: 300px;
                    max-width: 380px;
                  "
                  v-model="co_selectedGrade"
                  :options="gt_getGrades"
                  :close-on-select="true"
                  placeholder="All grades"
                >
                </multiselect>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="pt-0">
        <div class="container">
          <!-- Onclick Sidebar -->
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div class="row" v-if="filteredTutors.length > 0">
                <cmp-tutorcard
                  v-for="element in filteredTutors"
                  :key="element.id"
                  :p_email="element.id"
                  :p_subjectInfo="element.data"
                  :p_notSpecifiedSessionsExists="gt_notSpecifiedSessionsExists"
                  @e_addProductToCart="m_addProductToCart"
                  @e_allocationSession="m_allocationSession"
                ></cmp-tutorcard>
              </div>

              <div v-else class="text-center">
                <span v-if="st_subject == null"
                  >Sorry, this subject no longer exists!</span
                >
                <span v-else>
                  <p style="margin-top: 100px; margin-bottom: 20px">
                    Connect with us on whatsapp for assistance.
                  </p>
                  <a
                    class="btn btn-light"
                    :href="m_isMobile() ? m_whatsappUrl() : '#'"
                    @click="!m_isMobile() ? m_number() : ''"
                    :target="m_isMobile() ? '_blank' : ''"
                    >Get Assistance</a
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import c_tutorCard from "../cmp-components/cmp-tutorcard.vue";
import { mapState, mapGetters } from "vuex";
import c_multiselect from "vue-multiselect";
import { g_defaultPhone } from "@/lib-core/lib-helpers/lib-constants";
import {
  m_whatsappUrl,
  m_isMobile,
} from "../../lib-core/lib-helpers/lib-utils";
import { allocateSession } from "@/lib-core/lib-firebase";
export default {
  name: "cmp-subject",
  components: {
    "cmp-tutorcard": c_tutorCard,
    multiselect: c_multiselect,
  },
  data() {
    return { d_isAddToCart: false };
  },
  computed: {
    ...mapState({ st_subject: (state) => state.md_fire.st_subject }),
    ...mapGetters("md_fire", [
      "gt_curriculumsSelectItem",
      "gt_notSpecifiedSessionsExists",
      "gt_getGrades",
      "gt_sessionPrice"
    ]),
    ...mapGetters("md_auth", ["gt_authUser"]),
    co_selectedCurriculum: {
      get() {
        return this.$store.getters["md_fire/gt_curriculumSelected"];
      },
      async set(value) {
        let l_payload = null;
        if (value && value.key) l_payload = value.key;

        await this.$localforage.setItem("fs_curriculum", l_payload);
        this.$store.commit("md_fire/mt_setCurriculum", l_payload);
      },
    },
    filteredTutors: function () {
      if (!this.st_subject || !this.st_subject.tutors) return [];

      let ret = [];
      Object.keys(this.st_subject.tutors).forEach((p_element) => {
        if (
          this.st_subject.tutors[p_element].grade == this.co_selectedGrade ||
          this.co_selectedGrade == "" ||
          this.m_isOverlapping(
            this.st_subject.tutors[p_element].grade,
            this.co_selectedGrade
          )
        )
          ret.push({ id: p_element, data: this.st_subject.tutors[p_element] });
      });

      if (
        this.st_subject.education != "s" ||
        !this.$store.state.md_fire.st_curriculum
      )
        return ret;

      return ret.filter((p_element) => {
        if (
          p_element.data.curriculums != null &&
          p_element.data.curriculums
            .split(",")
            .indexOf(this.$store.state.md_fire.st_curriculum) != -1
        )
          return p_element;
      });
    },
    co_selectedGrade: {
      get() {
        return this.$store.getters["md_fire/gt_getHomepageGrade"];
      },
      set(p_grade) {
        this.$store.commit("md_fire/mt_setHomepageGrade", p_grade);
      },
    },
  },

  props: {
    p_id: {
      type: String,
      default: "",
    },
  },
  async mounted() {
    this.m_topFunction();
    setTimeout(async () => {
      this.m_fixColors();
      //console.log("options:", this.gt_curriculumsSelectItem);
      // console.log("selected key:", this.co_selectedCurriculum);
      await this.m_prepare();
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 1000);
  },
  methods: {
    m_isOverlapping(tutorgrade, selectedgrade) {
      let value = false;
      if (!selectedgrade || !tutorgrade) {
        value = true;
      } else {
        let mintutorgrade = parseInt(tutorgrade.split("-")[0]);
        let maxtutorgrade = parseInt(tutorgrade.split("-")[1]);
        let minselectedgrade = parseInt(selectedgrade.split("-")[0]);
        let maxselectedgrade = parseInt(selectedgrade.split("-")[1]);

        if (
          mintutorgrade <= maxselectedgrade &&
          minselectedgrade <= maxtutorgrade
        ) {
          value = true;
        }
      }
      return value;
    },
    m_fixColors() {
      document
        .getElementsByClassName("multiselect__option")
        .forEach((p_element) => {
          p_element.style.background = "#83bf6f !important";
        });
      document
        .getElementsByClassName("multiselect__tag")
        .forEach((p_element) => {
          p_element.style.background = "#83bf6f !important";
        });
      document
        .getElementsByClassName("multiselect__option--highlight")
        .forEach((p_element) => {
          p_element.style.background = "#83bf6f !important";
        });
      document
        .getElementsByClassName("multiselect__option--selected")
        .forEach((p_element) => {
          p_element.style.background = "#d2555b !important";
        });
    },
    m_topFunction() {
      window.document.body.scrollTop = 0; // For Safari
      window.document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    async m_prepare() {
      if (this.st_subject != null && this.st_subject.key == this.p_id) {
        return;
      }
      while (!this.m_isSubjectsLoaded) {
        // console.log("m_delay");
        await this.m_delay(500);
      }
      const l_subjectfromStore = await this.$store.dispatch(
        "md_fire/ac_getSubjectById",
        this.p_id
      );
      this.$store.commit("md_fire/mt_setSubject", l_subjectfromStore);
      return true;
    },
    m_isSubjectsLoaded() {
      return (
        this.$store.state.md_fire.st_subjects &&
        Object.keys(this.$store.state.md_fire.st_subjects).length > 0
      );
    },
    async m_addProductToCart(p_data) {
      let l_product = {
        ...this.st_subject,
        ...{ tutor: p_data.tutor },
        id: "standard",
        type: "s",
      };
      delete l_product["tutors"];
      l_product.price = this.gt_sessionPrice;
      l_product.quantity = p_data.quantity;
      await this.$store.dispatch("md_cart/ac_addProduct", l_product);
      await this.$localforage.setItem(
        "fs_cart",
        this.$store.state.md_cart.st_cartProducts
      );
      this.d_isAddToCart = true;
      setTimeout(() => {
        this.d_isAddToCart = false;
      }, 2000);
    },
    async m_allocationSession(p_data) {
      //update in sessions //studentsessions //tutorSessions if exists
      //change session status from not assigned to 'A'?
      ///get first/last session not specified
      let l_product = {
        subject: this.st_subject.key,
        ...{ tutor: p_data.tutor.email },
      };
      if (p_data.tutor.grade || p_data.tutor.curriculums)
        l_product.subjectInfo = {};

      if (p_data.tutor.grade) l_product.subjectInfo.grade = p_data.tutor.grade;

      if (p_data.tutor.curriculums)
        l_product.subjectInfo.curriculums = p_data.tutor.curriculums;

      let notSpecifiedSession = this.$store.getters[
        "md_fire/gt_getLastNotSpecifiedSession"
      ];

      if (!notSpecifiedSession) return;
      l_product.sessionId = notSpecifiedSession.sessionId;
      l_product.student = notSpecifiedSession.student;
      l_product.status = "A";
      l_product.date = notSpecifiedSession.date;
      l_product.type = "p";
      l_product.id = "p5";
      delete l_product.tutors;
      delete l_product.tutor.displayName;
      delete l_product.tutor.avatar;
      //console.log(l_product);

      const l_response = await allocateSession(l_product);
      //console.log(l_response);

      this.$router.push({ name: "sessions" });
    },
    async m_delay(ms) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          resolve();
        }, ms);
      });
    },
    m_whatsappUrl() {
      return m_whatsappUrl();
    },
    m_number() {
      alert("Support's number is +" + g_defaultPhone);
    },
    m_isMobile() {
      return m_isMobile();
    },
  },
};
</script>

<style scoped>
.multiselect__option {
  background: #83bf6f !important;
}
.multiselect__tag {
  background: #83bf6f !important;
}
.multiselect__option--highlight {
  background: #83bf6f !important;
}
.multiselect__option--selected {
  background: #d2555b !important;
}
</style>